<div class="justify-between h-16 animate-pulse">
  <span class="hidden sm:flex items-center">
    <span class="placeholder w-16" />
  </span>
  <span class="flex flex-1 flex-col justify-between min-w-0 h-10 max-sm:!ml-0">
    <dt>
      <div class="placeholder h-4" />
    </dt>
    <dd>
      <div class="placeholder h-4" />
    </dd>
  </span>
  <span class="placeholder-circle w-11" />
</div>
